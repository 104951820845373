
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'add-trainer',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      dialogImageUrl: '',
      load: false,
      imageUrl: '',
      imageEntityLogoUrl: '',
      dialogVisible: false,
      alertText: false,
      role_title: [] as any,
      actionCreate: false,
      createTrainer: false,
      text: '',
      file_name: '',
      module_type: 'trainer',
      employee: {
        employee_id: '',
        father_name: '',
        mother_name: '',
        nationality:'',
        name: '',
        dob: '',
        nid: '',
        bcn: '',
        passport: '',
        driving_license: '',
        mobile: '',
        email: '',
        gender: '',
        religion: '',
        present_address: '',
        present_district: '',
        permanent_address: '',
        is_trainer: '1',
        is_assessor: '',
        permanent_district: '',
      },
      user: {
        password: '',
      },
      bteb_registration_field: false,
      nsda_registration_field: false,
      entity_trainer: {
        is_bteb_registered: '',
        skill_level_bteb: '',
        occupation_bteb: '',
        pedagogy_level_bteb: '',
        pedagogy_part_achieved_bteb: '',
        bteb_registration_number: '',
        area_of_expertise: '',
        seip_master_trainer: '',
        is_guest_trainer: '',
          is_foreign_trainer: '',
        is_nsda_certified: '',
        skill_level_nsda: '',
        occupation_nsda: '',
        years_of_exp: '',
        nsda_registration_number: '',
        pedagogy_level_nsda: '',
        pedagogy_part_achieved_nsda: '',
        total_exp: 0,
      },
      professional_details: [
        {
          designation: '',
          organization: '',
          address: '',
          exp_from: '',
          exp_to: '',
        },
      ] as any,
      profession: {
        designation: '',
        organization: '',
        address: '',
        exp_from: '',
        exp_to: '',
      },
      educational_details: [
        {
          institute_name: '',
          level_of_education: '',
          subject: '',
          passing_year: '',
        },
      ] as any,
      education: {
        institute_name: '',
        level_of_education: '',
        subject: '',
        passing_year: '',
      },
      fromYear: [] as any,
      entityInstitute: {
        entity_info_id: '',
        institute_info_id: '',
        tranche_id: '',
        course_id: '',
      },
      certificate: {
        certificate_name: '',
        organization_name: '',
        year: '',
      },
      certificates: [
        {
          certificate_name: '',
          organization_name: '',
          year: '',
        },
      ] as any,
      url: '',
      tranche: [],
      educationLabel: [
        'Bellow Class-5',
        'PSC/Class-5',
        'JSC/Class-8',
        'SSC',
        'DAKHIL',
        'HSC',
        'ALIM',
        'Diploma',
        'BSC/HONOURS',
        'FAZIL',
        'KAMIL',
        'MASTERS',
        'PHD',
      ],
      entityRoles: [],
      courseList: [],
      courseInfoData: false,
      entityInfos: [],
      institutes: [],
      empDistricts: [],
      empCountry: [],
      nidData: {},
      loading: false,
      showTrainerInfo: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getDistrictForEmp();
     await this.getCountryForEmp();
    await this.getEntityInfos();
    await this.actioncheck();
    await this.getYear();
  },
  methods: {
    async courseInfo() {
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.entityInstitute.entity_info_id +
          '&tranche=' +
          this.entityInstitute.tranche_id +
          '&institute_info_id=' +
          this.entityInstitute.institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.courseList = response.data.data;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getYear() {
      let currentYear = new Date().getFullYear();
      for (let i = 50; i > 0; i--) {
        this.fromYear.push(currentYear);
        //console.log(this.fromYear);
        currentYear--;
      }
    },
    addcertificate() {
      this.certificates.push(JSON.parse(JSON.stringify(this.certificate)));
      console.log(this.certificates);
    },
    removecertificate() {
      this.certificates.length--;
    },
    addEducation() {
      this.educational_details.push(JSON.parse(JSON.stringify(this.education)));
    },
    removeEducation() {
      this.educational_details.length--;
    },
    addProfession() {
      this.professional_details.push(
        JSON.parse(JSON.stringify(this.profession))
      );
    },
    removeProfession() {
      this.professional_details.length--;
    },
    bteb_registered(e) {
      if (e.target.checked) {
        this.entity_trainer.is_bteb_registered = '1';
        this.bteb_registration_field = true;
      } else {
        this.entity_trainer.is_bteb_registered = '0';
        this.bteb_registration_field = false;
      }
    },
    nsda_certified(e) {
      if (e.target.checked) {
        this.entity_trainer.is_nsda_certified = '1';
        this.nsda_registration_field = true;
      } else {
        this.entity_trainer.is_nsda_certified = '0';
        this.nsda_registration_field = false;
      }
    },
    seip_trainer(e) {
      if (e.target.checked) {
        this.entity_trainer.seip_master_trainer = '1';
      } else {
        this.entity_trainer.seip_master_trainer = '0';
      }
    },
    seip_guest_trainer(e) {
      if (e.target.checked) {
        this.entity_trainer.is_guest_trainer = '1';
      } else {
        this.entity_trainer.is_guest_trainer = '0';
      }
    },
     seip_foreign_trainer(e) {
      if (e.target.checked) {
        this.entity_trainer.is_foreign_trainer = '1';
      } else {
        this.entity_trainer.is_foreign_trainer = '0';
      }
    },
    

    updatePhoto(e) {
      this.file_name = e.target.files[0];
      this.url = URL.createObjectURL(this.file_name);
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('user_id', user_id.id);
      formData.set('role_id', role_id);
      formData.set('file_name', this.file_name);
      for (var key in this.employee) {
        formData.set(key, this.employee[key]);
      }
      for (var key in this.user) {
        formData.set(key, this.user[key]);
      }
      for (var key in this.entity_trainer) {
        formData.set(key, this.entity_trainer[key]);
      }
      for (var key in this.entityInstitute) {
        formData.set(key, this.entityInstitute[key]);
      }
      formData.set('module_type', 'trainer');
      formData.set('certificates', JSON.stringify(this.certificates));
      formData.set(
        'educational_details',
        JSON.stringify(this.educational_details)
      );
      formData.set(
        'professional_details',
        JSON.stringify(this.professional_details)
      );
      //return(console.log(formData.get('file_name')));
      let data = {
        file_name: this.file_name,
        module_type: this.module_type,
        employee: this.employee,
        user: this.user,
        entity_trainer: this.entity_trainer,
        entityInstitute: this.entityInstitute,
      };
      let route = this.createTrainer
        ? 'entity/trainer/trainer-save-assessor'
        : 'entity/trainer/save';
      this.loading = true;

      await ApiService.post(route, formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.employee = {
                employee_id: '',
                email: '',
                father_name: '',
                mother_name: '',
                nationality:'',
                name: '',
                dob: '',
                nid: '',
                bcn: '',
                passport: '',
                driving_license: '',
                mobile: '',
                gender: '',
                religion: '',
                is_trainer: '',
                is_assessor: '',
                present_address: '',
                present_district: '',
                permanent_address: '',
                permanent_district: '',
              };
              this.user = {
                password: '',
              };
              this.entity_trainer = {
                is_bteb_registered: '',
                skill_level_bteb: '',
                occupation_bteb: '',
                pedagogy_level_bteb: '',
                pedagogy_part_achieved_bteb: '',
                bteb_registration_number: '',
                area_of_expertise: '',
                seip_master_trainer: '',
                is_guest_trainer: '',
                is_foreign_trainer: '',
                is_nsda_certified: '',
                skill_level_nsda: '',
                occupation_nsda: '',
                years_of_exp: '',
                nsda_registration_number: '',
                pedagogy_level_nsda: '',
                pedagogy_part_achieved_nsda: '',
                total_exp: 0,
              };
              this.entityInstitute = {
                course_id: '',
                tranche_id: '',
                entity_info_id: '',
                institute_info_id: '',
              };
              this.showTrainerInfo = false;
            });
          } else if (response.status == 200 && response?.data?.status == 'warning') {
            let err = '';
            Swal.fire({
              title: response.data.errors,
              html: 'Trainer email address must be unique.',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required fields',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    beforeEntityLogoRemove(file, fileList) {
      return confirm(`Cancel the transfert of ${file.name} ?`);
    },

    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
      async getCountryForEmp() {
      await ApiService.get('configurations/country/list')
        .then((response) => {
          this.empCountry = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    
    async getEntityInfos() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      let entity_id = this.entityInstitute.entity_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.entityInstitute.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
          entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    fetchNID() {
      console.log('API IS CALLING');
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Trainer') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Create') {
                console.log(actions[j].action_name);
                this.actionCreate = true;
              }
            }
          }
        }
      }
    },
    async nidverification(nidId) {
      let valid_nid_lengths = [10, 13, 17];
      if (valid_nid_lengths.includes(this.employee.nid.toString().length) && this.employee.dob) {
        await ApiService.get('configurations/employee/nid-verify?nid=' + nidId + '&dob=' + this.employee.dob)
          .then((response) => {
            if (
              response.data.status == 'success' &&
              response.data.data.employee
            ) {
              let role = response.data.data.employee;
              this.employee.employee_id = role.id;
              if (role.is_assessor && role.is_trainer) {
                Swal.fire({
                  title:
                    'This person already appointed as Assessor and also a Trainer',
                  text: '',
                  icon: 'warning',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                });
              } else if (role.is_trainer) {
                Swal.fire({
                  title: 'This person already appointed as a Trainer',
                  text: '',
                  icon: 'warning',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                });
              } else if (role.is_assessor) {
                Swal.fire({
                  title: 'This person already appointed as Assessor',
                  text: 'Are you want to create as a trainer?',
                  icon: 'warning',
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Create Trainer',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.createTrainer = true;
                  }
                });
              } else if (role.is_employee) {
                Swal.fire({
                  title: 'This person already appointed as an Employee',
                  text: 'Are you want to create as a trainer?',
                  icon: 'warning',
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Create Trainer',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.createTrainer = true;
                  }
                });
              }

              // console.log(this.role_title);
            } else if (
              response.data.status === 'success' &&
              !response.data.data.employee
            ) {
              this.showTrainerInfo = true;
               this.employee.name = response.data.data.nameEn;
              this.employee.mother_name = response.data.data.mother;
              this.employee.father_name = response.data.data.father;
              this.employee.gender = response.data.data.gender;
              this.employee.present_address = response.data.data.presentAddress.postOffice;
              this.employee.permanent_address = response.data.data.permanentAddress.postOffice;
              this.url = response.data.data.photo;

            } else {
              Swal.fire({
                title: 'An error occured!',
                text: 'Please check NID, DOB and try again.',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              });
              // error data not found on server and nidserver
              this.showTrainerInfo = false;
            }
          })
          .catch(({ response }) => {
            console.log(response);
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          });
      }else {
        Swal.fire({
                title: 'An error occured!',
                text: 'Please check NID and DOB. NID number must be 10/13/17 digits',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              });
      }
    },
  },
  setup() {
    const TrainerSchema = Yup.object().shape({
      // employeeEmail: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      presentDistrict: Yup.string().label('Present District'),
      name: Yup.string().label('Name'),
      nid: Yup.string().min(10).label('National ID'),
      // nid:Yup.number().test('len', 'Must be exactly 5 characters', (nid) => { if(nid) return nid.toString().length === 8; }),
      gender: Yup.string().label('Gender'),
      mobile: Yup.number().min(11).label('Mobile'),
    });
    return {
      TrainerSchema,
    };
  },
});
